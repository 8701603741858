<template>
  <div class="bbb">
    <div class="page-wrapper">
      <h1 class="nav-bar">我的</h1>

      <div class="detail_box">
        <div class="tx_box">
          <img class="my_img" src="@/assets/image/avatar5.png" alt="" />
          <div class="tx_info">
            <!-- <div class="name">{{ user.name || '姓名' }}</div> -->
            <div class="user_account">
              手机号：{{
                user.user_account == 'None' ? '无' : user.user_account
              }}
            </div>
          </div>
          <!-- <img class="edit-icon" src="@/assets/images/edit.png" /> -->
        </div>
        <div  class="info_box">
          <div class="box">
            <img class="icon" src="@/assets/images/xingbie.png" />
            <div class="box_label">性别</div>
            <div class="box_value">
              {{ user.sex == '' ? '未确定' : sex[user.sex] }}
            </div>
          </div>
          <div class="box">
            <img class="icon" src="@/assets/images/nianling.png" />
            <div class="box_label">年龄</div>
            <div class="box_value">
              {{ user.age == '' ? '未知' : user.age }}
            </div>
          </div>
        </div>
      </div>
      <div class="m-top">
        <h4>常用服务</h4>
        <div class="list">
          <div class="item" @click="Patient" bind:click="qb">
            <img class="icon" src="@/assets/images/jzrgl.png" />

            <div class="item-content">
              <span>就诊人</span>
              <van-icon name="arrow" color="#D6D6D6" />
            </div>
          </div>
          <div class="item" @click="order">
            <img class="icon" src="@/assets/images/wzzx.png" />

            <div class="item-content">
              <span>问诊中心</span>
              <van-icon name="arrow" color="#D6D6D6" />
            </div>
          </div>
          <div class="item" @click="getdrug">
            <img class="icon" src="@/assets/images/ypsfntccx.png" />

            <div class="item-content unlink">
              <span>药品能否统筹查询</span>
              <van-icon name="arrow" color="#D6D6D6" />
            </div>
          </div>
          <div class="item" @click="isAchives">
            <img class="icon" src="@/assets/images/edit.png" />
            <div class="item-content unlink">
              <span>是否有统筹档案</span>
              <van-icon name="arrow" color="#D6D6D6" />
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="tuichudenglu" @click="login">{{ login_text }}</div> -->

      <div class="m-top">
        <van-button
          @click="login"
          color="linear-gradient(to right, rgb(71,113,255), rgb(120,149,254))"
          type="primary"
          block
          >{{ login_text }}</van-button
        >
      </div>
    </div>
    <footer_ />
  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
export default {
  components: {
    footer_,
  },
  data() {
    return {
      user: {
        name: '',
        phone: '',
        sex: '',
        age: '',
        user_account: 'None',
      },
      sex: {
        man: '男',
        girl: '女',
      },
      login_text: '登录',
    };
  },
  methods: {
    login() {
      localStorage.clear();
      sessionStorage.removeItem('patientId');
      sessionStorage.removeItem('Drugs');
      sessionStorage.removeItem('Current');
      sessionStorage.removeItem('disease');
      sessionStorage.removeItem('past');
      sessionStorage.removeItem('family');
      sessionStorage.removeItem('drugSelected');
      // this.$router.push('/login')
      this.$router.push({
        name: 'login',
        query: {
          type: 'switch',
        },
      });
    },
    Patient() {
      this.$api.article.getihname().then((res) => {
        localStorage.setItem('ih_type', res.data.result.name);
        this.$api.article.postihuserinfo(res.data.result.name).then((res) => {
          if (res.data.code == '200') {
            this.$router.push('/Patient');
          }
        });
      });
    },
    order() {
      this.$router.push('/order');
    },
    getdrug() {
      this.$router.push('/getdrug');
    },
    isAchives(){
      this.$router.push('/ifAchives');
    },
    loadList() {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        this.user = user;
        this.login_text = '退出登录';
      } else {
        this.login_text = '登录';
      }
    },
  },
  created() {
    this.loadList();
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.my_img {
  width: 3.5rem;
  height: 3.5rem;
  background: #f2994a;
  border-radius: 50%;
}

.detail_box {
  padding: 0 1.5rem 0 1.5rem;

  position: relative;
}

.m-top {
  margin-top: 2rem;
  padding: 0 1.56rem;

  h4 {
    margin-bottom: 0.7rem;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    font-size: 0.88rem;
    color: #78828a;
    line-height: 1.5rem;
    display: flex;
  }
}
.tx_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 2.69rem 0 0;
  padding-right: 0.84rem;
  height: 3.5rem;

  .tx_info {
    margin-left: 0.81rem;
    height: 100%;
    color: #fff;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1rem;

      color: #171725;
    }

    .user_account {
      margin-top: 0.59rem;
      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.94rem;
      line-height: 0.91rem;
      color: #1b1b1b;
    }
  }
}

.edit-icon {
  width: 1.27rem;
  height: 1.27rem;
}

.info_box {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .box {
    padding: 1.13rem 0 1.5rem 1.44rem;
    width: 9.65rem;
    height: 3.75rem;
    background: #ffffff;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;

    .icon {
      width: 1.25rem;
    }

    .box_label {
      margin-left: 1rem;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 0.75rem;
      color: #9ca4ab;
      line-height: 1.25rem;
    }

    .box_value {
      margin-left: 0.75rem;
      font-family: PingFangSC;
      font-weight: 500;
      font-size: 1rem;
      color: #171725;
      line-height: 1.38rem;
    }
  }
}

.box_value {
  height: 21px;
  font-size: 15px;
  line-height: 21px;
}

.line {
  width: 1px;
  height: 25px;
  background: #afffd8;
}

.bj_btn {
  width: 216px;
  height: 56px;
  line-height: 56px;
  background: #ffc300;
  border-radius: 28px 0px 0px 28px;
  position: absolute;
  top: 64px;
  right: 0;
  color: #ffffff;
  font-size: 26px;
  padding-left: 30px;
  box-sizing: border-box;
}
.cz_btn {
  width: 216px;
  height: 56px;
  line-height: 56px;
  background: #ffc300;
  border-radius: 28px 0px 0px 28px;
  position: absolute;
  top: 150px;
  right: 0;
  color: #ffffff;
  font-size: 26px;
  padding-left: 30px;
  box-sizing: border-box;
}
.info {
  width: 420px;
  font-size: 24px;
  margin-left: 40px;
  color: #333;
}

.info > view {
  display: flex;
}

.info > view + view {
  margin-top: 20px;
}

.info .info_label {
  width: 100px;
  flex-shrink: 0;
}

.bj_box {
  text-align: center;
}

.editBox {
  border-radius: 5px;
  background: #fff;
  width: 90%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.editBox_title {
  text-align: center;
  line-height: 100px;
}

.btn {
  margin: 30px;
  display: flex;
  justify-content: space-between;
}
.LogOut {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 200px;
}
.LogOut button {
  border-radius: 8px;
}
</style>

<style scoped lang="less">
.page-wrapper {
  position: relative;
  padding-bottom: 4rem;
  &::before {
    content: '';
    position: absolute;

    background: url('~@/assets/images/page-bg-1.png') no-repeat;
    background-size: cover;

    width: 100%;
    height: 18.88rem;
    z-index: 0;
  }

  div,
  h1 {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
  }

  .nav-bar {
    padding-top: 2.63rem;
    width: 100%;
    font-family: PingFangSC;
    font-weight: 600;
    font-size: 1.13rem;
    color: #171725;
    line-height: 1.63rem;
    text-align: center;
  }

  .m-top {
    .list {
      display: flex;
      flex-direction: column;

      .item {
        width: 100%;
        height: 3.94rem;

        display: flex;
        align-items: center;

        .icon {
          margin-right: 1.19rem;
          width: 1.25rem;
          height: 1.25rem;
        }

        .item-content {
          position: relative;
          flex: 1;
          display: flex;
          justify-content: space-between;

          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 1rem;
          color: #171725;
          line-height: 4rem;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 0.06rem;
            background-color: #e9ebed;
          }
        }

        .unlink::after {
          display: none;
        }
      }
    }
  }

  .tuichudenglu {
    margin: 4rem 0 4.81rem;

    width: 100%;
    text-align: center;

    font-family: SourceHanSansCN;
    font-weight: 400;
    font-size: 0.88rem;
    color: #ff1b1b;
    line-height: 1.5rem;
  }
}
</style>
